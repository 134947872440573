<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="search-contain">
      <Row>
        <Col :lg="{span: 8, offset: 8}" :md="{span: 12}">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
        </Col>
      </Row>
    </div>

    <!-- <div class="add-box">
      <Row>
        <Col span="2" offset="22">
          <div class="member-oprate">
            <router-link class="new-doc fr" :to="{name: 'ServicesProblem'}">查看问题</router-link>
          </div>
        </Col>
      </Row>
    </div> -->

    <div class="add-box">
      <Row>
        <Col span="4" offset="20">
          <div class="member-oprate">
            <router-link class="new-doc fr" :to="{name: 'LinghuoAdd'}" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">添加信息</router-link>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box pt50">
      <Table stripe :columns="columns" :data="servicesList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delPlan" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>

  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import linghuoService from '@/services/linghuoService';
export default {
	data() {
		return {
			servicesList: [],
			pageTotal: 0,
			fpmodal: false,
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				member_id: this.$route.params.member_id,
			},
			del: {
				services_ids: '',
			},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '提货时间', key: 'create_time', align: 'center' },
				{ title: '产品名称', key: 'drug_name', align: 'center' },
				{ title: '提货数量', key: 'number', align: 'center' },
				{ title: '服务人', key: 'staff_name', align: 'center' },
				{
					title: '操作',
					key: 'score',
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									on: {
										click: () => {
											this.$router.push({
												name: 'LinghuoLook',
												params: { services_id: this.servicesList[params.index].id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.del_one_Plan(params.index);
										},
									},
								}),
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'LinghuoLook',
												params: { services_id: this.servicesList[params.index].id },
											});
										},
									},
								}),
							]);
						}
					},
				},
			],
			power: [],
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	created() {
		this.getServicesList();
		this.power = this.userInfo.power;
	},
	methods: {
		getServicesList() {
			linghuoService.listServices(this.searchForm).then((data) => {
				this.servicesList = data.list;
				this.pageTotal = data.row_size;
				this.del.services_ids = '';
			});
		},
		searchSubmit() {
			this.searchForm.page = 1;
			this.getServicesList();
		},
		handleChangeTime(value) {
			this.searchForm.page = 1;
			this.searchForm.start_time = value[0];
			this.searchForm.end_time = value[1];
			this.getServicesList();
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getServicesList();
		},
		selectOne(selection) {
			let arrId = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].id);
			}
			this.del.services_ids = arrId.join(',');
		},
		delPlan() {
			if (!this.del.services_ids) {
				this.$Message.warning('请先选择要删除的记录');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选领货记录吗',
					onOk: () => {
						this.okdel();
					},
					// onCancel: () => {
					//   this.del.services_ids = ''
					// }
				});
			}
		},
		del_one_Plan(index) {
			let param = this.servicesList[index].id;
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除该记录吗',
				onOk: () => {
					linghuoService.deleteServices({ services_ids: JSON.stringify(param) }).then(() => {
						// this.searchForm.page = 1
						this.getServicesList();
						this.$Message.warning('删除成功');
					});
				},
			});
		},
		okdel() {
			linghuoService.deleteServices(this.del).then(() => {
				// this.searchForm.page = 1
				this.getServicesList();
				this.del.services_ids = '';
			});
		},
	},
};
</script>
<style lang="css" scoped>
.mt20 {margin-top: 20px;}
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}
</style>
